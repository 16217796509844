








import { Vue, Component, Watch } from "vue-property-decorator";
import List from "./list.vue";
import Info from "./info.vue";
import Question from "./question.vue";
import { wrong,api_customer } from "@/api";
type type = "list" | "info" | "question";

@Component({
    components: {
        List,
        Info,
        Question,
    },
})
export default class extends Vue {
    type: type = "list";


	page = 1;
    count = 0;

	what = 0

	list: wrong[] = [];

	@Watch("page")
    async get_list() {
        if (this.list.length === this.count && this.list.length !== 0) {
            return this.$message.error("추가 내용이 없습니다.");
		}
		this._loading = true
        const { results, count } = await api_customer.get_wrong({
            page: this.page
        }).finally(()=>{
			this._loading = false
		})
        this.list = [...this.list, ...results];
        this.count = count;
    }


    created() {
        this.get_list();
	}

	clear(){
		this.list = []
		this.page = 1
		this.get_list()
	}

	@Watch('what')
	watch_what(what:number){
		if(what + 1 > this.list.length){
			this.page ++
		}
	}


    // @Watch("type")
    // watch_type(type: type) {
    //     const tabs = document.getElementById("customer_tab") as HTMLElement;
    //     if (type === "list") {
    //         tabs.style.display = "flex";
    //     } else {
    //         tabs.style.display = "none";
    //     }
	// }

    @Watch("$route",{immediate:true})
    watch_route(res:any){
        if(res.query.subject_title && res.query.chapter_title && res.query.exam_num){
            this.type = 'question'
        }else if(res.query.ele){
            this.type = 'question'
        }
    }

	destroyed(){
		const tabs = document.getElementById("customer_tab") as HTMLElement | null;
		if(tabs === null){
			return
		}
		tabs.style.display = "flex";
	}

	get disabled():boolean{
		return this.count <= this.list.length
	}
}
