















































































import { Vue, Component, Emit, Model, Prop } from "vue-property-decorator";
import { qa } from "@/api";
import { OtherModule } from "@/store/other";
import { UserModule } from "@/store/user"
import { Encryption } from "@/util/encryption";
import { api_customer } from "@/api";
type type = "list" | "info" | "question";

@Component
export default class extends Vue {
  @Model("update:type", { required: true, type: String })
  type!: type;
  @Emit("update:type")
  change_type(type: type): type {
    if(this.user_info){
      return type;
    }else{
      this.$confirm('로그인 한 사용자만 신고가 가능합니다. 지금 로그인하시겠습니까? ',{
        confirmButtonText:'로그인하기',
        cancelButtonText:'취소',
      }).then(() => {
          this.$router.push({
            path:'/login',
            query:{
              last:Encryption.base_enc(this.$route.fullPath)
            }
          })
      })
      return 'list'
    }
  }

  @Prop({ required: true })
  list!: qa[];

  @Prop({ required: true })
  disabled!: boolean;

  @Model("update:page", { type: Number, required: true })
  readonly page!: number;
  @Emit("update:page")
  more() {
    return this.page + 1;
  }

  @Emit("clear")
  clear() {
    return null;
  }

  @Model("update:what", { type: Number, required: false })
  readonly what!: number;
  @Emit("update:what")
  change_what(index: number) {
    this.change_type("info");
    return index;
  }

  get user_info(){
    return UserModule.info
  }

  get is_mobile(): boolean {
    return OtherModule.is_mobile;
  }

  toChange(ele: any) {
    this.$router.push({
      path:'/customer/report',
      query:{
        ele:Encryption.base_enc(JSON.stringify(ele))
      }
    })
  }

  toDel(ele: any) {
    this.$confirm('是否确认删除? ',{
      confirmButtonText:'是',
      cancelButtonText:'否',
    }).then(async() => {
        this._loading = true;
        await api_customer.del_wrong(ele.id).finally(() => {
        this._loading = false;
        this.clear()
      });
    })
  }
}
