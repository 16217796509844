






























































































































import { Vue, Component, Model, Emit, Prop } from "vue-property-decorator";
import { api_customer, qa } from "@/api";
import Inner from "@/components/inner/index.vue";
import { OtherModule } from "@/store/other";
type type = "list" | "info" | "question";

@Component({
  components: {
    Inner,
  },
})
export default class extends Vue {
  @Model("update:type", { required: true, type: String })
  readonly type!: type;
  @Emit("update:type")
  change_type(type: type): type {
    return type;
  }

  @Model("update:what", { type: Number, required: true })
  readonly what!: number;
  @Emit("update:what")
  change_what(index: number) {
    return index;
  }

  @Prop({ required: true })
  count!: number;

  @Prop({ required: true })
  list!: qa[];

  get info(): qa {
    return this.list[this.what];
  }

  get is_mobile(): boolean {
    return OtherModule.is_mobile;
  }
}
