













































































































import { Vue, Component, Model, Emit, Watch } from "vue-property-decorator";
import Editor from "@/components/editor/index.vue";
import { api_customer, post_wrong } from "@/api";
import { editor_length } from "@/util/string";
import { OtherModule } from "@/store/other";
import { Encryption } from "@/util/encryption";
type type = "list" | "info" | "question";

@Component({
  components: { Editor },
})
export default class extends Vue {
  @Model("update:type", { required: true, type: String })
  type!: type;

  @Emit("update:type")
  ret_list(): type {
    return "list";
  }

  @Emit("clear")
  clear() {
    return null;
  }

  options = [
    {
      label: "오타신고",
      value: 1,
    },
    {
      label: "오답신고",
      value: 2,
    },
  ];
  info: post_wrong = {
    content: "",
    title: "",
    // subject: "",
    // chapter:"",
    category: "",
    // examination: "",
  };

  id:string | number = ''

  // changeExamination(e: any) {
  //   let num = +e;
  //   if (num < 0) return (this.info.examination = 0);
  //   this.info.examination = num;
  // }

  async submit() {
    if (this.info.title === "") {
      return this.$message.error("제목을 작성해 주세요.");
    }
    if (this.info.content === "") {
      return this.$message.error("내용을 작성해 주세요.");
    }
    if (this.info.category === "") {
      return this.$message.error("신고 종류를 선택해주세요.");
    }
    if (editor_length(this.info.content) > 1000) {
      return this.$message.error(
        "글자 수 제한이 초과되었습니다. 1000자 이내로 작성해 주세요."
      );
    }
    if(this.$route.query.ele){
      this._loading = true;
      await api_customer.patch_wrong(this.info,this.id).finally(() => {
        this._loading = false;
      });
      this.$message.success("문의사항이 제출 되었습니다.");
      this.clear();
      this.ret_list();
      return
    }
    this._loading = true;
    await api_customer.post_wrong(this.info).finally(() => {
      this._loading = false;
    });
    this.$message.success("문의사항이 제출 되었습니다.");
    this.clear();
    this.ret_list();
  }

  get is_mobile(): boolean {
    return OtherModule.is_mobile;
  }

    created(){
      // if(this.$route.query.subject_title && this.$route.query.chapter_title && this.$route.query.exam_num){
      //   let subject:any = this.$route.query.subject_title
      //   this.info.subject = subject
      //   let chapter:any = this.$route.query.chapter_title
      //   this.info.chapter = chapter
      //   let exam_num:any = this.$route.query.exam_num
      //   this.info.examination = exam_num
      // }
      if(this.$route.query.ele){
        let ele = JSON.parse(Encryption.base_dec(this.$route.query.ele as string))
        this.info.content = ele.content
        this.info.title = ele.title
        this.info.category = ele.category
        this.id = ele.id
      }
    }
}
